@import "../../Styles/constants";


.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgba(128, 21, 189, .3);
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: $purple;
}

.swal2-styled.swal2-confirm {
  display: table;
  margin: 0 10px;
  min-width: 100px;
  border-radius: 20px;
  padding: 8px 30px;
  border: none;
  font-size: 0.937rem;
  font-weight: 600;
  color: white;
  background-color: #f27474;
  transition: background-color .2s ease-in-out;
  cursor: pointer;
  box-shadow: none !important;
  outline: none !important;
  width: fit-content;

  &:hover {
    background-color: rgb(220, 82, 82);
  }
}

.swal2-input.swal2-inputerror, .swal2-file.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  box-shadow: 0 1px 0 0 #DC5252 !important;
  border-bottom: 1px solid #DC5252 !important;
}

.swal2-styled.swal2-cancel {
  border-radius: 20px;
  font-size: 0.937rem;
  font-weight: 600;
  margin: 0 10px;
  padding: 8px 30px;
}

;

.swal2-icon.swal2-question {
  border-color: #8015b77a;
  color: #8015b7;
}

.swal2-popup, .swal2-modal, .login-popup, .swal2-show {
  -webkit-box-shadow: 0 2px 5px 0 #AFAFAF !important;
  -moz-box-shadow: 0 2px 5px 0 #AFAFAF !important;
  box-shadow: 0 2px 5px 0 #AFAFAF !important;
}

body.swal2-toast-shown .swal2-container.swal2-top-end{
  z-index: 99999;
}

.login-container {
  background-color: #f7f9fb !important;
}


.login-popup {
  padding: 2% 4%;
}

.login-input {
  border: none;
  box-shadow: none !important;
  background-color: transparent;
  border-bottom: 2px solid #c7c7c7;
  border-radius: 0;
  height: 50px;
  transition: border .3s, box-shadow .3s;

  &:hover {
    box-shadow: 0 1px 0 0 $purple;
    border-bottom: 2px solid $purple;
  }

  &:focus {
    box-shadow: 0 1px 0 0 $purple;
    border: none;
    border-bottom: 2px solid $purple;
  }

  &::placeholder {
    transition: color .3s;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:active {
    border: none;
    border-bottom: 2px solid $purple;
  }
}

.login-title {
  font-size: 1.5rem;
}