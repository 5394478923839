.edit-box-wrapper{
  a{
    text-decoration: none;
  }
}

.status{
  cursor: pointer;
}

.delete{
  cursor: pointer;
}