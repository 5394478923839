@import "constants";

.error-block{
  display: flex;
  height: calc(100vh - #{$header_height+30px});
  padding-top: 10%;


  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11rem;
    width: 40%;
    height: 40%;
    text-shadow: 5px 5px 0 #ffa70d;
    font-weight: 700;
    color: $purple;
  }

  .info{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 40%;
    padding: 20px 0;

    .link{
      text-decoration: none;
      color: $purple;
      transition: transform .2s ease-in-out;
      width: max-content;
      font-size: 1.2rem;
      font-weight: 500;

      &:after{
        content: '';
        display: block;
        height: 2px;
        background-color: #60148A;
        width: 0;
        margin: auto;
        transition: width .1s ease-in-out;
      }

      &:hover:after{
        width: 100%;
      }
    }
  }
}