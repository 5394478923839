@import "./_constants";

.login-page {
  //min-height: calc(100vh - #{$header_height});
  min-height: 100vh;
  background: $background_grey;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-align: right;
  font-family: 'Quicksand', sans-serif;

  .logo {
    height: 110px;
    margin-bottom: 10px;
  }

  .input-wrapper {
    width: 100%;
  }

  .forgot-link {
    color: $text_color;
    font-size: 14px;
    float: right;
    cursor: pointer;
    text-decoration: none;

    &:hover{
      color: $purple
    }
  }

  h3 {
    color: $text_color;
    margin-bottom: 25px;
  }

  form {
    width: 400px;
    background-color: white;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 10px 15px 0;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
      width: 100%;
      border: none;
      font-size: 0.83rem;
      padding: 7px;
      margin: 10px 0;
      color: $text_color;
      border-bottom: 1px solid rgb(148, 148, 148);
      outline: none;
      border-radius: 3px;
      transition: .5s ease-in-out;
      background-color: white;

      &:focus {
        border-bottom: 1px solid $purple;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 300px white inset;
      }

      &::placeholder {
        color: $text_color;
        transition: .2s;
      }

      &:focus::placeholder {
        opacity: 0;
      }
    }

    button {
      margin: 20px auto 0 auto;
    }

  }
}