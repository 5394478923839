@import "../../Styles/constants";

.main-menu {

  &-wrapper {
    position: fixed;
    top: 10px;
    width: 20%;
    height: 100%;
    padding: $header_height 30px 0 0;
  }

  li:first-child{
    .menu-item-icon{
      background-size: 207%;
    }
  }

  .menu-item {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &.active-like {
      .menu-item-icon {
        background-position: right;
      }
      .menu-item-text, .menu-subList-text {
        color: $purple;
        font-weight: 700;
      }
    }

    &-icon {
      background-size: 200%;
      background-position: left;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 30px;
    }

    &-text {
      font-size: 0.875rem;
      font-weight: 500;
      color: $text_color;
    }

    &:hover {
      .menu-item-icon {
        background-position: right;
      }

      .menu-item-text, .menu-subList-text {
        color: $purple;
      }
    }
  }

  .menu-item-collapsible {

    .menu-item {

      &-rotate::after{
        transform: rotateX(180deg);
      }

      &.title {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: 20px;
          top: 20px;
          width: 0;
          height: 0;
          border-top: 5px solid $text_color;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          transition: transform .3s ease-in-out
        }
      }
    }
  }

  .menu-subList {
    padding-left: 70px;

    .menu-item {
      padding: 5px 0;
    }

    &-text {
      font-size: 0.75rem;
      color: $text_color;
    }

  }

}