@import "../../Styles/constants";

.trustees-content{
  width: 30%;
  border: 1px solid $light-grey;
  box-shadow: 0 2px 5px -2px #000000a3;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 3%;

  &:nth-child(3n+2) {
    margin-left: calc((100% - 90%)/3);
    margin-right: calc((100% - 90%)/3);
    margin-bottom: 3%;
  }

  &-delete{
    color: #b71515;
    width: max-content;
    position: absolute;
    right: 3%;
    top: 3%;
    cursor: pointer;
    background-color: rgba(183, 21, 21, 0.10);
    border-radius: 5px;
    line-height: 0;
    padding: 5px;
    transition: background-color .2s;

    svg{
      transform: rotate(45deg);
    }

    &:hover{
      background-color: rgba(183, 21, 21, 0.20);
    }
  }

  .field{
    width: auto;
  }
}