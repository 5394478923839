@import "./../../Styles/_constants";

.page-block {
  background-color: white;
  margin-bottom: 30px;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow .4s ease-in-out;

  -webkit-box-shadow: 0px -4px 0px $purple, 0 2px 5px 0 #c3c3c3;
  -moz-box-shadow: 0px -4px 0px $purple, 0 2px 5px 0 #c3c3c3;
  box-shadow: 0px -4px 0px $purple, 0 2px 5px 0 #c3c3c3;

  .trustees {
    position: relative;

    &-block {
      display: flex;
      flex-wrap: wrap;
    }

    .add-trustees {
      position: absolute;
      border-radius: 50%;
      color: white;
      background-color: #8015B7;
      width: 40px;
      height: 40px;
      right: 0;
      top: -60px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover{
        &:after{
          animation: TrusteesPulse 1.7s ease infinite;
        }
      }

      svg{
        z-index: 10;
      }

      &:after{
        content: '';
        position: absolute;
        height: 38px;
        width: 38px;
        background-color: #8015B7;
        border-radius: 50%;
      }
    }
  }

  .content-upload-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    .edit-box {
      position: absolute;
      right: 0;
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;
    }
  ;

    .section {
      display: flex;
      align-items: center;
      width: 100%;

      .selectSection {
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
      }
    }

    .featured-block {
      margin-left: 3%;
      display: flex;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 1.25rem;
        margin-right: 10px;
      }

      .checkbox-button {
        display: flex;
        cursor: pointer;
        transform-origin: 50% 50%;
        transform-style: preserve-3d;
        transition: transform .14s ease;
        margin: 0;

        &:hover {
          .cbx span {
            border-color: $purple;
          }
        }

        .inp-cbx {
          display: none;

          &:checked + .cbx span {
            background: $purple;
            border-color: $purple;
            animation: wave 0.4s ease;

            svg {
              stroke-dashoffset: 0;
            }

            &:before {
              transform: scale(3.5);
              opacity: 0;
              transition: all 0.6s ease;
            }
          }


        }

        .cbx {
          margin: auto;
          -webkit-user-select: none;
          user-select: none;
          cursor: pointer;

          span {
            display: inline-block;
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            transform: scale(1);
            vertical-align: middle;
            border: 1.5px solid #9098a9;
            transition: all 0.2s ease;

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              fill: none;
              stroke: #fff;
              stroke-width: 2;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-dasharray: 16px;
              stroke-dashoffset: 16px;
              transition: all 0.3s ease;
              transition-delay: 0.1s;
              transform: translate3d(-50%, -50%, 0);
            }

            &:before {
              content: "";
              width: 100%;
              height: 100%;
              background: $purple;
              display: block;
              transform: scale(0);
              opacity: 1;
              border-radius: 50%;
            }
          }
        }

        @-moz-keyframes wave {
          50% {
            transform: scale(0.9);
          }
        }

        @-webkit-keyframes wave {
          50% {
            transform: scale(0.9);
          }
        }

        @-o-keyframes wave {
          50% {
            transform: scale(0.9);
          }
        }

        @keyframes wave {
          50% {
            transform: scale(0.9);
          }
        }

      }
    }

    .no-icon {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(177, 177, 177, 0.4);
      text-align: center;
      font-size: 3.9rem;
      position: relative;

      &:before {
        content: 'No Icon';
        line-height: 13px;
        color: white;
        font-size: 13px;
        font-weight: 600;
        position: absolute;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
      }
    }

    .icon-preview {
      width: 65px;
      height: 65px;
      border-radius: 5px;
    }
  }

  .optional-content {
    position: relative;
    margin-top: 50px;

    &:before{
      content: '';
      position: absolute;
      width: 110%;
      padding: 15px;
      left: -5%;
      top: -30%;
      background-color: #f7f9fb;
      box-shadow: inset 0 2px 5px 0 #c3c3c3, 0 4px 0 $purple,;
    }

    &:after{
      content: 'Optional';
      position: absolute;
      top: 0;
      right: 2%;
      color: $medium-grey;
    }
  }
}

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  margin-right: 3%;
  margin-bottom: 30px;

  &-name {
    position: relative;
    line-height: 1rem;
    font-size: 0.75rem;
    color: #b1b1b1;
    margin-bottom: 10px;
  }

  &.input {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $light-grey;
      transform: scaleX(.95);
      transition: background-color .4s ease-in-out, transform .2s ease-in-out;
    }

    &:hover, &:focus {
      &::after {
        background-color: $purple;
        transform: scaleX(1);
      }
    }
  }

  &.question {
    width: 100%;

    &::after {
      transform: scaleX(.98);
    }
  }

  &.icon {
    width: max-content;
  }

  &.date {

  }
}

.text-input {
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  transition: border .2s ease-in-out;

  &.empty {
    //background-color: rgba(255, 0, 0, 0.5);
    border: 1px solid red;
  }
}


.nav-item {
  color: $medium-grey;
}

.nav-tabs .nav-link {
  margin: 0 15px;
  font-size: 13px;
  transition: .3s;
  cursor: pointer;
  font-weight: 600;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $purple;
    transform: scaleX(0);
    transition: transform .2s ease-in-out;
  }

  &.active {
    &::after {
      transform: scaleX(1);
    }
  }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border: 1px solid transparent;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border: 1px solid transparent;
}

.nav-link.active:hover {
  color: #495057;
  background-color: #fff;
  border: 1px solid transparent;
}

.tab-pane {
  padding: 3%;
}

@keyframes TrusteesPulse {
  0%{
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}