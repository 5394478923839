@import "../../Styles/constants";


.page {
  width: 100%;
  padding-top: 140px;

  h1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: default;
    margin: 0;

    a{
      color: $purple;
    }
  }

  &-body {
    margin-top: 25px;
    //background-color: white;
    //border-radius: 3px;
    //min-height: calc(100vh - 250px);
    //box-shadow: 0 2px 5px 0 #cecece;
    //padding: 3%;
    //overflow: hidden;

    .search{
      width: 100%;
      padding: 3% 3% 1% 3%;
      display: flex;
      justify-content: space-between;

      &-input {
        position: relative;
        width: 40%;
        margin-right: 2%;

        &-text{
          border: none;
          padding: 7px;
          width: 100%;
          color: $text_color;
          outline: none;
          text-indent: 25px;
          font-weight: 600;
          font-size: 1rem;
          transition: box-shadow .2s ease-in-out;
          box-shadow: 0 1px 0 0 rgba(146, 146, 146, 0.3);

          &::placeholder{
            font-weight: 500;
            color: rgba(0, 0, 0, 0.3);
            transition: color .2s ease-in-out;
          }

          &:focus{
            box-shadow: 0 1.7px 0 0 rgba(128,21,183,0.75);
          }

          &:focus::placeholder{
            color: transparent;
          }
        }

        &-icon {
          position: absolute;
          left: 2%;
          bottom: 30%;
          font-size: 1rem;
          line-height: 0;
          color: $medium-grey;
        }

      }

      &-date {
        position: relative;
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .from-block{
          span{
            position: absolute;
            font-size: 0.875rem;
            top: -40%;
            color: $medium-grey;
          }
        }

        .to-block{
          span{
            position: absolute;
            font-size: 0.875rem;
            top: -40%;
            color: $medium-grey;
          }
        }

        &-input{
          padding: 0 2%;
          border: none;
          outline: none;
          transition: box-shadow .2s ease-in-out;
          box-shadow: 0 1px 0 0 rgba(146, 146, 146, 0.3);
          cursor: text;

          &:focus{
            box-shadow: 0 1.7px 0 0 rgba(128,21,183,0.75);
          }
        }
      }
    }

    &.pageInner {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }

    .element-collection {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 3%;

      .no-search-result{
        min-height: 42.5vh;
        display: flex;
        color: $text_color;
        font-size: 1.25rem;
        font-weight: 600;
        padding-top: 20px;
        animation: text-fade-in .5s ease-in-out forwards;
      }

      .element {
        position: relative;
        //flex: 1 1 0;
        margin-bottom: 3%;
        width: calc(94% / 3);
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);

        &:nth-child(3n + 2) {
          margin: 0 3% 3% 3%;
        }

        &-img {
          position: relative;
          width: 100%;
          padding-top: 60%;
          background-position: top center;
          background-repeat: no-repeat;
          background-size: cover;

          &.noImg {
            &::after {
              content: 'No image';
              position: absolute;
              top: 55%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 1.3rem;
              color: $light-grey;
            }
          }
        }

        &-text {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 3% 2% 3% 5%;

          span {
            font-size: 1rem;
            font-weight: 500;
            white-space: nowrap;
          }

          .dropdown {
            position: unset;

            .dropdown-menu.show {
              padding: 0;
              right: 0;
              bottom: 0;
              top: unset !important;
              left: unset !important;
              transform: none !important;
              will-change: unset !important;
              border: none !important;
            }
          }

          .edit-button {
            display: table;
            background-color: transparent !important;
            color: $text_color;
            border: none;
            padding: 0;
            line-height: unset;
            border-radius: 0;
            transition: none;
            outline: none !important;
            box-shadow: none !important;

            &-img {
              padding: 10px 15px 10px 20px;
              cursor: pointer;
            }
          }

          &.active {
            .edit-box {
              display: block;
            }
          }
        }

      }
    }
  }
}

@keyframes text-fade-in {
  from{
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }

  to{
    opacity: 1;
    transform: none;
  }
}