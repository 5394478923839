@import "../../Styles/constants";

.page {
  &-header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 0 30px;
    width: calc(80% - 48px);
    top: 75px;
    padding: 30px 40px 20px 40px;
    z-index: 98;
    background-color: white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(154, 154, 154, 0.5);
  }
}