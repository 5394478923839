@import "../../Styles/constants";

.demo-wrapper{
  border: 1px solid $light-grey;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;


  .rdw-editor-toolbar{
    margin: 0;
    border: none;
    border-bottom: 1px solid $purple;
    border-radius: 0 20px 0 0;
    display: flex;
    justify-content: center;

    .rdw-dropdown-carettoopen {
      top: 40%;
    }
  }

  .rdw-inline-wrapper {
    border-right: 1px solid $light-grey;
    padding-right: 5px;
    margin-right: 5px;

    div{
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px 10px 4px 10px;
      padding: 15px 10px;
      transition: border .3s ease-in-out, background-color .3s;

      &:hover{
        box-shadow: none;
        background-color: #ebebeb;
        border-color: #8015B7;
      }
    }
  }

  .rdw-block-wrapper {
    background-color: rgba(235, 235, 235, 0.6);
    border-radius: 4px 10px 4px 10px;
    transition: border .3s ease-in-out, background-color .3s;
    border: 1px solid transparent;

    &:hover{
      box-shadow: none;
      background-color: #ebebeb;
      border: 1px solid #8015B7;
    }

    .rdw-dropdown-wrapper {
      border: none;
      box-shadow: none;
      background-color: transparent;

      &:hover {
        box-shadow: none;
        background-color: transparent;
      }
    }
  }

  .rdw-history-wrapper {
    border-right: 1px solid $light-grey;
    padding-right: 5px;
    margin-right: 5px;

    div{
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px 10px 4px 10px;
      padding: 15px 10px;
      transition: border .3s ease-in-out, background-color .3s;

      &:hover{
        box-shadow: none;
        background-color: #ebebeb;
        border-color: #8015B7;
      }
    }
  }

  .rdw-list-wrapper {
    border-right: 1px solid $light-grey;
    padding-right: 5px;
    margin-right: 5px;

    div{
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px 10px 4px 10px;
      padding: 15px 10px;
      transition: border .3s ease-in-out, background-color .3s;

      &:hover{
        box-shadow: none;
        background-color: #ebebeb;
        border-color: #8015B7;
      }
    }
  }

  .rdw-text-align-wrapper {
    border-right: 1px solid $light-grey;
    padding-right: 5px;
    margin-right: 5px;

    div{
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px 10px 4px 10px;
      padding: 15px 10px;
      transition: border .3s ease-in-out, background-color .3s;

      &:hover{
        box-shadow: none;
        background-color: #ebebeb;
        border-color: #8015B7;
      }
    }
  }

  .rdw-link-wrapper {
    border-right: 1px solid $light-grey;
    padding-right: 5px;
    margin-right: 5px;

    div{
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px 10px 4px 10px;
      padding: 15px 10px;
      transition: border .3s ease-in-out, background-color .3s;

      &:hover{
        box-shadow: none;
        background-color: #ebebeb;
        border-color: #8015B7;
      }
    }
  }

  .rdw-remove-wrapper {
    div{
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px 10px 4px 10px;
      padding: 15px 10px;
      transition: border .3s ease-in-out, background-color .3s;

      &:hover{
        box-shadow: none;
        background-color: #ebebeb;
        border-color: #8015B7;
      }
    }
  }

  .rdw-option-active {
    box-shadow: none;
    border-color: #8015B7;
    background-color: #ebebeb !important;
  }

  .demo-editor{
    padding: 0 15px;
    color: $text_color;
  }
}



