@import "../../Styles/constants";

.buttons {
  display: flex;

  button {
    &.save {
      background-color: $orange;
      color: $text_color;

      &:hover {
        background-color: #ff7f00c4;
      }
    }
  }
}