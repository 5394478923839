@import "constants";

//@font-face {
//  font-family: 'Fedra Sans';
//  src: url('./../Fonts/FedraSansBook/FedraSansBook.eot');
//  src: url('./../Fonts/FedraSansBook/FedraSansBook.eot?#iefix') format('embedded-opentype'),
//  url('./../Fonts/FedraSansBook/FedraSansBook.woff') format('woff'),
//  url('./../Fonts/FedraSansBook/FedraSansBook.ttf') format('truetype'),
//  url('./../Fonts/FedraSansBook/FedraSansBook.svg#FedraSansBook') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}
//
//
//html * {
//  font-family: 'Fedra Sans'!important;
//}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

h1, h2, h3, h4, h5, h6, p, span {
  color: $text_color;
}

h5 {
  font-size: 1rem;
  line-height: 1.875rem;
  //font-weight: 300;
}

button {
  display: table;
  cursor: pointer;
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none!important;
}

.page-layout {
  display: flex;
  flex-direction: row;
  //min-height: calc(100vh - #{$header_height});
  min-height: 100vh;
  background-color: $background_grey;
  padding: 30px 30px 0 30px;
}

.main-window-wrapper {
  width: 80%;
  margin-left: 20%;
}

$transition-default: 0.25s ease-out 0.05s;
.switcher {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  position: relative;
  font-size: 1em;
  line-height: 0;
  height: 0;
  width: 0;

  &:before,
  &:after {
    content: '';
    font-size: 1em;
    position: absolute;
    cursor: pointer;
  }

  &:before {
    border-radius: 30 / 16 * 1em;
    background: #cdcdcd;
    height: 14px;
    width: 30px;
    left: -8px;
    top: -7px;
    transition: background-color $transition-default;
  }

  &:after {
    box-shadow: 0 .0625em .375em 0 #666;
    border-radius: 50%;
    background: white;
    height: 10px;
    width: 10px;
    transform: translate(-6px, -5px);
    transition: transform $transition-default;
  }

  &:checked {
    &:after {
      transform: translate(10px, -5px);
    }

    &:checked:before {
      background-color: $purple;
    }
  }
}


.edit-box-wrapper {
  position: absolute;
  bottom: 25px;
  right: 20px;
  width: 160px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
  padding: 10px;
}

.edit-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 20px !important;
    height: auto;
  }

  &.edit, &.delete {
    padding-right: 5px;

    .edit-icon {
      cursor: pointer;
      color: #3f3e40;
      font-size: 1.3rem;
      text-decoration: none;
      width: 20px;
      padding: 0;
    }
  }

  &.delete {
    span, .edit-icon {
      color: #ED531F;
    }
  }

  &.status {
    position: relative;
    font-size: 15px;
    padding-right: 25px;

    span{
      margin-right: 15px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.main-button {
  display: table;
  margin: 0 5px;
  min-width: 100px;
  border-radius: 20px;
  padding: 8px 30px;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  background-color: $purple;
  transition: background-color .2s ease-in-out;
  cursor: pointer;
  box-shadow: none !important;
  outline: none !important;
  width: fit-content;

  &:hover {
    background-color: #600096;
  }
}

.fixed-plus {
  position: fixed;
  z-index: 9999;
  bottom: 50px;
  right: 50px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: white;
  background-color: $purple;
  box-shadow: 0 0 15px 0 rgba(134, 134, 134, 0.78);
  outline: none !important;
  border: none !important;
  transition: background-color .3s ease-in-out;
  transform: rotate(0deg);

  &:hover {
    background-color: #600096;
    animation: spin .7s forwards ease-in-out;
  }
}

.text-purple {
  color: $purple;
}

.delete-block-button {
  display: flex;
  margin-left: auto;
  padding: 0;
  margin-right: 10px;
  background-color: transparent;
  outline: none;
  border: none;
  color: $medium-grey;
  font-size: 25px;
  transition: color .3s;
  overflow-x: hidden;

  svg{
    z-index: 1;
    background-color: white;
  }

  span {
    margin-right: 15px;
    font-size: 1rem;
    font-weight: 600;
    color: #e10000;
    transform: translate(100%,0);
    opacity: 0;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: transform, opacity;
    transition-delay: 0.1s, 0s;
  }

  &:hover {
    outline: none;
    color: #e10000;

    span{
      transform: translate(0,0);
      opacity: 1;
      transition-duration: 0.3s;
      transition-property: transform, opacity;
      transition-delay: 0s, 0.1s;
    }
  }


}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

}

@media #{$mobile} {
}