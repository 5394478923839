@import "../../Styles/_constants.scss";

.gallery {

  &-head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .dropzone {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 30%;
      margin-bottom: 3%;

      //&:before {
      //  content: '';
      //  position: absolute;
      //  top: -5px;
      //  left: -5px;
      //  width: calc(100% + 5px);
      //  height: calc(100% + 5px);
      //  //border: 2px dashed #ffa200;
      //  border-radius: 5px;
      //  overflow: hidden;
      //  transition: all .3s ease;
      //}

      //&:hover {
      //  &:before {
      //    border: 2px dashed #60148A;
      //  }
      //}

      &-text {
        position: relative;
        z-index: 0;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        border-radius: 5px;
        overflow: hidden;
        padding: .5rem 2rem;
        border: 2px dashed #b1b1b1;
        transition: border .3s ease-in-out;

        .upload {
          transform: translate(0, 80%);
          transition: transform .3s ease-in-out;
        }

        .accepted-formats {
          transform: translate(0, 300%);
          transition: transform .3s ease-in-out;
        }

        .text-purple {
          transform: translate(0, 300%);
          transition: transform .3s ease-in-out;
        }

        &:hover {
          border: 2px dashed $purple;

          .upload {
            transform: translate(0, 0);
          }

          .accepted-formats {
            transform: translate(0, 0);
          }

          .text-purple {
            transform: translate(0, 0);
          }
        }

        //&, &::before, &::after {
        //  box-sizing: border-box;
        //}

        //&::before {
        //  content: '';
        //  position: absolute;
        //  z-index: -2;
        //  left: -50%;
        //  top: -50%;
        //  width: 200%;
        //  height: 200%;
        //  //background-color: #399953;
        //  background-repeat: no-repeat;
        //  background-size: 50% 50%, 50% 50%;
        //  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        //  background-image: linear-gradient($light-grey, $light-grey),
        //  linear-gradient(transparent, transparent),
        //  linear-gradient($light-grey, $light-grey),
        //  linear-gradient(transparent, transparent);
        //  //animation: rotate 4s linear infinite;
        //}
        //
        //&:hover {
        //  &::before {
        //    transition: background-color .3s ease-in-out;
        //    background-image: linear-gradient($purple, $purple),
        //    linear-gradient(transparent, transparent),
        //    linear-gradient($purple, $purple),
        //    linear-gradient(transparent, transparent);
        //    animation: rotate 4s linear infinite;
        //  }
        //}

        //&::after {
        //  content: '';
        //  position: absolute;
        //  z-index: -1;
        //  left: 2px;
        //  top: 2px;
        //  width: calc(100% - 4px);
        //  height: calc(100% - 4px);
        //  background: white;
        //  border-radius: 4px;
        //}

        p {
          font-size: 0.8rem;
          margin-bottom: 0;

          &:first-of-type {
            font-size: 1.25rem;
            margin-bottom: 10px;
            //line-height: 3rem;
            color: $purple;
          }
        }
      }

      //button {
      //  position: absolute;
      //  right: -147px;
      //}
    }
  }

  .img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .img-wrapper {
      position: relative;
      height: 15vw;
      margin: 0 3% 3% 0;
      min-width: 150px;
      border-radius: 4px;
      //overflow: hidden;

      .main-image {
        height: 100%;
      }

      &.inError {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed red;

        .error-text {
          font-size: 1.375rem;
          color: red;
          text-align: center;
          min-width: 250px;
        }
      }

      .img {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        box-shadow: 0 2px 5px -2px #000000a3;
      }

      .download, .delete {
        cursor: pointer;
        position: absolute;
        top: 10px;
        border-width: 2px;
        border-style: solid;
        border-color: white;
        border-radius: 100%;
        overflow: hidden;

        &:hover {
          filter: brightness(1.2);
        }
      }

      .download {
        right: 50px;
      }

      .delete {
        right: 10px;
      }
    }

    .worker-wrapper {
      position: relative;
      height: 27vw;
      width: 30%;
      margin: 0 3% 3% 0;
      border-radius: 4px;
      border: 1px solid $light-grey;
      box-shadow: 0 2px 5px 0 #ebebeb;

      &.inError {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed red;
      }

      .img {
        height: 55%;
        //background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        //border: 2px dashed #b1b1b1;
        transition: border .3s ease-in-out;
      }

      .download, .delete {
        cursor: pointer;
        position: absolute;
        top: 10px;
        border-width: 2px;
        border-style: solid;
        border-color: white;
        border-radius: 100%;
        overflow: hidden;

        &:hover {
          filter: brightness(1.2);
        }
      }

      .download {
        right: 50px;
      }

      .delete {
        right: 10px;
      }

      .worker-fields {
        padding: 10px 20px;

        .field {
          width: 100%;

        //  position: relative;
        //  display: flex;
        //  flex-direction: column;
        //  justify-content: space-between;
        //  width: 30%;
        //  margin-right: 3%;
        //  margin-bottom: 30px;
        //
          &-name {
            line-height: 0.875rem;
            font-size: 0.75rem;
            margin-bottom: 5px;
          }
        }
      }

      .error-text {
        font-size: 1.375rem;
        color: red;
        text-align: center;
      }
    }

  }

}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}