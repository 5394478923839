@import "../../Styles/constants";

header {
  width: 100%;
  height: $header_height;
  background-color: $purple;
  position: fixed;
  z-index: 9999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  font-size: 20px;

  &-logo {
    height: 90%;
  }

  .logout-block {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      top: 0;
      left: -20px;
      background-color: #60148A;
    }

    img {
      width: 20px;
      margin-right: 15px;
    }

    span {
      color: white;
      font-size: 1rem;
    }
  }

}

