@import "../../Styles/constants";

.with-animation {
  .page-block:last-of-type {
    box-shadow: 0 0 0 3px $purple, 0 2px 5px 0 #cecece;
    animation: createBlock .2s forwards ease-in-out;
    animation-delay: .3s;
    transform: scale(.9);
  }
}

@keyframes createBlock {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}