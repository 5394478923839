
$header_height: 80px;
$background_grey: #f7f9fb;
$light-grey: #c7c7c7;
$medium-grey: #929292;
$purple: #8015B7;
$orange: #FFCB05;
$text_color: #3F3E40;




$mobile: 'screen and (max-width: 767px)';
$tablet: 'screen and (max-width: 1023px)';