@import "../../Styles/constants";

.forgot-password{
  form{
    width: max-content !important;

    .auth-form-actions{
      text-align: center;
      a{
        color: $purple;
        text-decoration: none;
      }
    }

    .main-button{
      margin-bottom: 15px;
    }

    .password-image{
      position: relative;
      margin-bottom: 20px;

      img{
        width: 110px;
        height: 110px;
        border-radius: 50%;
        user-select: none;
      }

      &:before{
        content: '';
        display: block;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 5%);
        box-shadow: 0 0 0 rgba(128, 21, 183, 0.4);
        animation: pulse 2s infinite;
        animation-delay: 1s;
      }

    }

    .input-wrapper{
      margin-top: 10px;

      .error{
        box-shadow: 0 3px 0 0 #e10000;
      }

      .popup{
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 400;
        font-size: 14px;
        height: 40px;
        width: max-content;
        padding: 0 30px;
        border-radius: 10px;
        background-color: #e10000;
        position: absolute;
        top: 54%;
        right: 110%;

        &:after{
          content: 'Password does not match';
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-weight: 400;
          font-size: 14px;
        }

        &:before{
          content: '';
          border-left: 20px solid #e10000;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          position: absolute;
          border-radius: 10px;
          top: 0;
          left: 97.3%;
        }
      }
    }
  }
}

.reset-password-inputs {
  position: relative;

  input{
    border-radius: 0 !important;
    border-bottom: 1px solid $light-grey !important;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(128, 21, 183, 0.4);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(128, 21, 183, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 21, 183, 0);
  }
}

@keyframes fadeInPopup {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes fadeOutPopup {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}